<template>
  <div style="width: 100%; height: 472px; background-image: url('images/top_banner.jpg');">
    <div style="padding-top: 400px; padding-left: 1700px;">
      <Button label="食慧安应用管理系统" @click="app_sys_button_clicked"/>
    </div>
  </div>

  <!--入口-->
  <div class="card" style="padding-left:84px; padding-right: 84px; padding-top: 24px; padding-bottom: 0px;">
    <div class="flex justify-content-between flex-wrap card-container purple-container">
      <div class="flex align-items-center justify-content-center bg-purple-500 font-bold text-white border-round m-2"
           v-for="(item, index) in items" :key="index">
        <!--入口图片-->
        <div class="out_box" :style="'background-image: url(' + item.img + ');'">
          <div style="height: 451px;"></div>
          <div style="height: 60px; padding-left: 300px; padding-top: 10px;">
            <Image src="images/enter.png" alt="" @click="login_to_button_clicked(item.url)"/>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--手机端-->
  <div class="card" style="padding-left:84px; padding-right: 84px; padding-top: 24px; padding-bottom: 0px;">
    <div class="flex justify-content-between flex-wrap card-container purple-container">
      <!--手机App-->
      <div class="flex align-items-center justify-content-center bg-purple-500 font-bold text-white border-round m-2">
        <div class="out_box" style="background-image: url('images/app.jpg');">
          <div style="height: 451px;"></div>
          <div style="height: 60px; padding-left: 12px; padding-top: 12px; padding-right: 12px; text-align: right;">
            <Button icon="pi pi-android" label="下载到手机" @click="download_to_mobile_button_clicked" style="margin-right: 12px;"/>
            <Button icon="pi pi-microsoft" label="下载到电脑" @click="download_to_pc_button_clicked"/>
          </div>
        </div>
      </div>

      <!--微信公众号-->
      <div class="flex align-items-center justify-content-center bg-purple-500 font-bold text-white border-round m-2">
        <div class="out_box" style="background-image: url('images/wxgzh.jpg')">
          <div style="height: 451px;"></div>
          <div style="height: 60px; padding-left: 12px; padding-top: 12px; padding-right: 12px; text-align: right;">
            <Button icon="pi pi-eye" label="关注" @click="wxgzh_button_clicked" style="margin-right: 12px;"/>
          </div>
        </div>
      </div>

      <!--微信小程序-->
      <div class="flex align-items-center justify-content-center bg-purple-500 font-bold text-white border-round m-2">
        <div class="out_box" style="background-image: url('images/wxxcx.jpg')">
          <div style="height: 451px;"></div>
          <div style="height: 60px; padding-left: 12px; padding-top: 12px; padding-right: 12px; text-align: right;">
            <Button icon="pi pi-qrcode" label="扫码" @click="wxxcx_button_clicked" style="margin-right: 12px;"/>
          </div>
        </div>
      </div>

      <!--报表管理系统-->
      <div class="flex align-items-center justify-content-center bg-purple-500 font-bold text-white border-round m-2">
        <div class="out_box" style="background-image: url('images/hipereport.jpg')">
          <div style="height: 451px;"></div>
          <div style="height: 60px; padding-left: 12px; padding-top: 12px; padding-right: 12px; text-align: right;">
            <Button icon="pi pi-microsoft" label="下载" @click="hipereport_download_button_clicked" style="margin-right: 12px;"/>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div style="padding-top: 24px; padding-bottom: 6px; width: 100%; text-align: center; font-size: 8px; color: darkgray;">
    北京食慧安信息技术有限公司
  </div>
  <div style="padding-top: 0px; padding-bottom: 24px; width: 100%; text-align: center; font-size: 8px; color: darkgray;">
    京ICP备2022020104号-1
  </div>

  <!--对话框-->
  <Dialog header="下载到手机" v-model:visible="displayAppErCode" >
    <div class="card">
      <div class="card-container yellow-container">
        <div class="block bg-indigo-500 font-bold text-white text-center p-4 border-round mb-3">
          请用手机浏览器扫描下面的二维码
        </div>
        <div class="block bg-indigo-500 font-bold text-center p-4 border-round mb-3">
          <Image src="images/shihuian-app-ercode.png" />
        </div>
      </div>
    </div>
  </Dialog>

  <!--对话框-->
  <Dialog header="微信公众号" v-model:visible="displayWxgzhErCode" >
    <div class="card">
      <div class="card-container yellow-container">
        <div class="block bg-indigo-500 font-bold text-white text-center p-4 border-round mb-3">
          请用微信扫描下面的二维码
        </div>
        <div class="block bg-indigo-500 font-bold text-center p-4 border-round mb-3">
          <Image src="images/shihuian-wxgzh-ercode.jpg" />
        </div>
      </div>
    </div>
  </Dialog>

  <!--对话框-->
  <Dialog header="微信小程序" v-model:visible="displayWxxcxErCode" >
    <div class="card">
      <div class="card-container yellow-container">
        <div class="block bg-indigo-500 font-bold text-white text-center p-4 border-round mb-3">
          <!--请用微信扫描下面的二维码-->
          暂未上线，请随时关注！
        </div>
        <div class="block bg-indigo-500 font-bold text-center p-4 border-round mb-3">
          <!--Image src="images/shihuian-wxxcx-ercode.jpg" /-->
        </div>
      </div>
    </div>
  </Dialog>

  <!--对话框-->
  <Dialog header="食慧安报表管理系统" v-model:visible="displayHipereportDownload" >
    <div class="card">
      <div class="card-container yellow-container">
        <div class="block bg-indigo-500 font-bold text-white text-center p-4 border-round mb-3">
          食慧安报表管理系统用于食慧安餐饮管理平台的打印和数据导出，请同时下载安装 Wps Office
        </div>
        <div class="block bg-indigo-500 font-bold text-center p-4 border-round mb-3">
          <div>
            <Button icon="pi pi-microsoft" label="下载食慧安报表管理系统" @click="download_hipereport_button_clicked" style="width: 300px; margin-bottom: 12px;"/>
          </div>
          <div>
            <Button icon="pi pi-microsoft" label="下载 Wps Office" @click="download_wps_button_clicked" style="width: 300px;"/>
          </div>
        </div>
      </div>
    </div>
  </Dialog>

</template>

<script>
import {ref, defineComponent} from "vue"
import Image from 'primevue/image'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

export default defineComponent({
  name: "IndexPage",
  components: {
    Image,
    Button,
    Dialog,
  },
  setup () {
    let baseurl = ref(window.location.protocol + '//' + window.location.host.split(':')[0])

    let items = ref([
      {
        title: '餐饮中心管理系统',
        desc: '学校、医院、政府机关等中大型组织',
        img: 'images/dining2.jpg',
        url: baseurl.value + ':9020',
      },
      {
        title: '餐饮运营管理系统',
        desc: '餐饮运营公司',
        img: 'images/dining3.jpg',
        url: baseurl.value + ':9020' + '/indexcoc.html',
      },
      {
        title: '食品供应管理系统',
        desc: '食品供应商',
        img: 'images/dining4.jpg',
        url: baseurl.value + ':9010',
      },
      {
        title: '食堂安全运行管理系统',
        desc: '食堂安全管理工作人员',
        img: 'images/dining5.jpg',
        url: baseurl.value + ':9030',
      }
    ])

    function app_sys_button_clicked () {
      window.open(baseurl.value + ':9000', 'app')
    }

    function login_to_button_clicked (url) {
      console.log(baseurl.value)
      if (url.length > 0) {
        window.open(url, 'app')
      }
    }

    function download_to_pc_button_clicked() {
      window.open('食慧安.apk', 'download')
    }

    let displayAppErCode = ref(false)
    function download_to_mobile_button_clicked () {
      displayAppErCode.value = true
    }

    let displayWxgzhErCode = ref(false)
    function wxgzh_button_clicked () {
      displayWxgzhErCode.value = true
    }

    let displayWxxcxErCode = ref(false)
    function wxxcx_button_clicked () {
      displayWxxcxErCode.value = true
    }

    let displayHipereportDownload = ref(false)
    function hipereport_download_button_clicked () {
      displayHipereportDownload.value = true
    }
    function download_hipereport_button_clicked () {
      window.open('setup.exe', 'download')
    }
    function download_wps_button_clicked () {
      window.open('https://platform.wps.cn/', 'download')
    }

    return {
      items, displayAppErCode, displayWxgzhErCode, displayWxxcxErCode, displayHipereportDownload,
      app_sys_button_clicked,
      login_to_button_clicked,
      download_to_pc_button_clicked,
      download_to_mobile_button_clicked,
      wxgzh_button_clicked,
      wxxcx_button_clicked,
      hipereport_download_button_clicked,
      download_hipereport_button_clicked,
      download_wps_button_clicked,
    }
  }
})
</script>

<style scoped>
.out_box {
  width: 359px;
  height: 511px;
}
</style>