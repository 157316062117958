import { createApp } from 'vue'
import App from './index.vue'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                          //icons
import 'primeflex/primeflex.scss'

const app = createApp(App)
app.use(PrimeVue)

app.mount('#app')
